/* >========== | Color Variables | ==========< */

$font-color:#282828;
$link-color:#f7a63d;
$link-hover-color:#23527c;
$border-color:#EEE;
$unobtrusive-gray:rgba(0, 0, 0,0.02);
$slight-gray:rgba(0, 0, 0,0.3);

/* >========== | Size Variables | ==========< */

$social-icon-size : 4rem;


/* >========== | Animation Variables | ==========< */

/* >========== | General Styles | ==========< */

html,body{

    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    color:$font-color;
    font-family: 'Source Sans Pro', sans-serif;
  max-width:100%;
  overflow-x:hidden;
  scroll-behavior: smooth;
}

img{

    max-width:100%;

}

a {

  color:$link-color;
  font-weight: 400;

  &:hover {
    transition: background-color 300ms ease;
  }

}

iframe {
  background-image: url("./../img/loading2.gif");
  background-repeat: no-repeat;
  background-size: cover;
}

#react-page > div > a > span > svg {
  fill: white;
}




/* >========== | Specific Styles | ==========< */

/* === Container === */

.main-container{

    padding-bottom:10em;
}

/* === Title === */

.very-small-text {
  font-size:0.5em;
}

.small-line-height {
  line-height: 0.5;
  opacity: 0.3;
}

.title-name{
    font-weight:700;
    font-size:4em;
    text-align:center;
  font-family: 'Abril Fatface', cursive;
}

.title-subtitle{

    text-align: center;
    font-size:3em;
    font-weight:100;
  font-family: 'Source Sans Pro', sans-serif;
}

.title-text{
    padding:1em;
    text-align: center;
    font-weight:100;
    font-size:2em;
  font-family: 'Source Sans Pro', sans-serif;
}

.subtitle{
  margin-top:5rem;
    background-color:$unobtrusive-gray;
    padding:0.3em;
    text-align:center;
   font-weight:700;
  font-family: 'Abril Fatface', cursive;
}

.platforms__container {
  display:flex;
  justify-content: center;


}

.platforms__item {
  width:$social-icon-size;
  height:$social-icon-size;
  margin-right:2rem;
  position:relative;
  & > p {

    color:$slight-gray;
    position:absolute;
    transform: translate(-50%, -100%);
    bottom:-3rem;
    left:50%;
    opacity: 0;
    white-space: nowrap;
    overflow: visible;
  }

  &:hover > p {
    transition: transform 300ms;
    transform: translate(-50%, 50%);
    opacity: 1;
  }
}


.icon__link {

  width:100%;
  height:100%;
  border-radius:100%;
  background-color:$link-color;
  display:inline-flex;
  justify-content: center;
  align-items: center;
  color: rgba(255,255,255,1);

  &:focus {
    transform: scale(1.2);
    color: rgba(255,255,255,1);
    text-decoration:none;
  }

  &:hover {
    transform: scale(1.2);
    text-decoration: none;
    background-color:$link-hover-color;

    & > i {
      color:white;
      transform: rotate(-30deg);
      transition: transform 100ms ease-in-out;
    }
  }

  & > i {
    font-size:$social-icon-size / 2;
  }
}

/* === Images === */

.profile-image{

   margin:0 auto;
    padding:1em;
    width:20%;
    border-radius:100%;

    display:block;

    @media screen and (max-width: 992px){

        padding:0em;
        width:35%;
    }

}

.work-image:hover {

  transform: scale(1.3,1.3);
  transition:all 300ms ease;
  object-position:50%;


}

.logo__img {

  width:100%;
  padding:2em 4em;



}

/* === Portfolio === */

.design-portfolio-piece {
  margin-top:2em;
}


.design-portfolio__description {
  max-width:85%;
  font-weight:200;
  margin-bottom:1em;
  line-height: 2em;

  &:target {
    animation-name: flash;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  @media screen and (max-width: 992px){

    max-width:100%;
  }
}

.portfolio-piece{

    margin-top:5em;

}

.portfolio-piece-properties__label {
  display:none;
}

.portfolio-piece:hover .portfolio-piece-properties__label {
  display:inherit;
  animation: slide-down .3s ease-in;
}

@keyframes slide-down {
  0% { opacity: 0; -webkit-transform: translateY(-100%); }
  100% { opacity: 1; -webkit-transform: translateY(0); }
}

.portfolio-image-container{

    overflow:hidden;


     @media screen and (max-width: 992px){

        padding:0em 3em;


    }


}

.portfolio-piece-year{

    font-weight:200;
    text-align:right;
    font-size:1.4em;
    display:block;
    margin-bottom:1em;
  font-family: 'Anonymous Pro', monospace;


    @media screen and (max-width: 992px){

        text-align:center;
        font-size:2.5em;
        font-weight:400;
    }

}

.portfolio-piece-properties{
    font-weight:200;
    padding:0em;
    list-style-type: none;
    font-size:1.6em;


    @media screen and (max-width: 992px){

        background-color:$unobtrusive-gray;
        padding:1em;
    }

}

.portfolio-piece__title {

  font-family: 'Source Sans Pro', sans-serif;
  font-weight:400;

}

.smaller-li{

    font-size:0.8em;
  font-weight: lighter;
  font-family: 'Source Sans Pro', sans-serif;

}

.subtitle-li{

    font-size:0.8em;
    font-weight: 700;

}

/* === Footer === */

footer.footer{

    background-color:$unobtrusive-gray;
    margin-top:5em;
    position:fixed;
    bottom:0;
    width:100%;


}

.mail-link{

    display:block;
    padding:0.5em;
    text-align:center;
    font-size:1.3em;
    color:$font-color;

    &:hover{

        text-decoration:none;
        color:black;
        -webkit-transition: font-size 0.5s; /* Safari */
        transition: font-size 0.5s;

        #mail-icon-text{

            display:inline;
        }




    }

}

#mail-icon{

    display:inline;
    padding:0.5em;


}

#mail-icon-text{

    display:none;
    color:$font-color;


}


/* >========== | Pace.js Styles | ==========< */ 


.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $font-color;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
